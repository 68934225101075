<template>
  <v-row class="login-page align-center">
    <v-container>
      <v-row>
        <v-col cols="12" xs="12" md="6" class="pa-0">
          <v-card
            flat
            tile
            color="light-blue"
            class="login-left rounded-l-lg d-flex justify-center align-center"
          >
            <h1 class="white--text text-center">Welcome to SMS Bulking</h1>
          </v-card>
        </v-col>

        <v-col cols="12" xs="12" md="6" class="pa-0">
          <v-card
            flat
            color="white"
            height="100%"
            tile
            class="d-flex justify-center align-center rounded-r-lg"
          >
            <v-form class="login-form pa-5 d-flex flex-column">
              <v-text-field
                required
                filled
                v-model="form.userName"
                label="İstifadəçi adı"
                color="light-blue"
                :error-messages="
                  $v.form.userName.$error && !$v.form.userName.required
                    ? ['İstifadəçi adını daxil edin']
                    : []
                "
              />

              <v-text-field
                required
                filled
                v-model="form.password"
                label="Şifrə"
                color="light-blue"
                :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
                :type="showPassword ? 'text' : 'password'"
                @click:append="showPassword = !showPassword"
                :error-messages="
                  $v.form.password.$error && !$v.form.password.required
                    ? ['Şifrəni daxil edin']
                    : []
                "
              />

              <v-btn
                block
                depressed
                color="light-blue"
                dark
                class="login-submit py-6"
                @click="submit"
              >
                Daxil ol
              </v-btn>
            </v-form>

            <v-snackbar color="red" top right v-model="snackbar" timeout="2000">
              İstifadəçi adı və ya şifrə yanlış daxil edilib.
              <template v-slot:action="{ attrs }">
                <v-btn
                  fab
                  text
                  x-small
                  v-bind="attrs"
                  @click="snackbar = false"
                >
                  <v-icon>mdi-close</v-icon>
                </v-btn>
              </template>
            </v-snackbar>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </v-row>
</template>

<script>
import { createNamespacedHelpers, mapGetters } from "vuex";

const { mapActions } = createNamespacedHelpers("auth");
import { required } from "vuelidate/lib/validators";

export default {
  name: "Login",
  computed: {
    ...mapGetters("auth", ["isAuthenticated"]),
  },
  data: () => ({
    form: {
      userName: "",
      password: "",
    },
    showPassword: false,
    snackbar: false,
  }),
  validations: {
    form: {
      userName: {
        required,
      },
      password: {
        required,
      },
    },
  },
  methods: {
    ...mapActions(["login"]),
    submit() {
      this.$v.$touch();
      if (!this.$v.$invalid) {
        const credentials = {
          userName: this.form.userName,
          password: this.form.password,
        };
        this.login(credentials)
          .then(() => {
            if (this.isAuthenticated) {
              const query = this.$router.currentRoute;
              this.$router.push(query.redirect ? query.redirect : "/dashboard");
              this.$eventBus.$emit("notification", {
                type: "success",
                message: "Ugurlu login oldu",
              });
            }
          })
          .catch(() => {
            this.$eventBus.$emit("notification", {
              type: "error",
              message: "İstifadəçi adı və ya şifrə yanlış daxil edilib.",
            });
            this.userName = "";
            this.password = "";
          });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.login-page {
  min-height: 100vh;
  background-color: #eee;

  .container {
    max-width: 900px;

    .login-left {
      min-height: 420px;
    }

    .login-form {
      width: 80% !important;

      .login-submit {
        text-transform: unset;
      }
    }
  }
}
</style>
